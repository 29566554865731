"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.grpcUserStatsAccountSubscriber = void 0;
const webSocketUserStatsAccountSubsriber_1 = require("./webSocketUserStatsAccountSubsriber");
const grpcAccountSubscriber_1 = require("./grpcAccountSubscriber");
class grpcUserStatsAccountSubscriber extends webSocketUserStatsAccountSubsriber_1.WebSocketUserStatsAccountSubscriber {
    constructor(grpcConfigs, program, userStatsAccountPublicKey, resubOpts) {
        super(program, userStatsAccountPublicKey, resubOpts);
        this.grpcConfigs = grpcConfigs;
    }
    async subscribe(userStatsAccount) {
        if (this.isSubscribed) {
            return true;
        }
        this.userStatsAccountSubscriber = new grpcAccountSubscriber_1.grpcAccountSubscriber(this.grpcConfigs, 'userStats', this.program, this.userStatsAccountPublicKey, undefined, this.resubOpts);
        if (userStatsAccount) {
            this.userStatsAccountSubscriber.setData(userStatsAccount);
        }
        await this.userStatsAccountSubscriber.subscribe((data) => {
            this.eventEmitter.emit('userStatsAccountUpdate', data);
            this.eventEmitter.emit('update');
        });
        this.eventEmitter.emit('update');
        this.isSubscribed = true;
        return true;
    }
}
exports.grpcUserStatsAccountSubscriber = grpcUserStatsAccountSubscriber;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.grpcUserAccountSubscriber = void 0;
const webSocketUserAccountSubscriber_1 = require("./webSocketUserAccountSubscriber");
const grpcAccountSubscriber_1 = require("./grpcAccountSubscriber");
class grpcUserAccountSubscriber extends webSocketUserAccountSubscriber_1.WebSocketUserAccountSubscriber {
    constructor(grpcConfigs, program, userAccountPublicKey, resubOpts) {
        super(program, userAccountPublicKey, resubOpts);
        this.grpcConfigs = grpcConfigs;
    }
    async subscribe(userAccount) {
        if (this.isSubscribed) {
            return true;
        }
        this.userDataAccountSubscriber = new grpcAccountSubscriber_1.grpcAccountSubscriber(this.grpcConfigs, 'user', this.program, this.userAccountPublicKey, undefined, this.resubOpts);
        if (userAccount) {
            this.userDataAccountSubscriber.setData(userAccount);
        }
        await this.userDataAccountSubscriber.subscribe((data) => {
            this.eventEmitter.emit('userAccountUpdate', data);
            this.eventEmitter.emit('update');
        });
        this.eventEmitter.emit('update');
        this.isSubscribed = true;
        return true;
    }
}
exports.grpcUserAccountSubscriber = grpcUserAccountSubscriber;

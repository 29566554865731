import { produce } from 'immer';
import { create } from 'zustand';

export type SizeString = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type HeightString = 'short' | 'medium' | 'tall';

export interface WindowSizeStore {
	set: (x: (s: WindowSizeStore) => void) => void;
	get: (x: any) => WindowSizeStore;
	realtimeWidth: number;
	realtimeHeight: number;
	// Not using SizeString as the type so that that vscode shows the possible values on hover
	size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	heightString: 'short' | 'medium' | 'tall';
}

export const useWindowSizeStore = create<WindowSizeStore>((set, get) => {
	const setFn = (fn) => set(produce(fn));

	return {
		set: setFn,
		get: () => get(),
		realtimeWidth: 0,
		realtimeHeight: 0,
		size: 'md',
		heightString: 'medium',
	};
});

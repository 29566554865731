import {
	BN,
	BigNum,
	MAX_PREDICTION_PRICE,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
} from '@drift-labs/sdk';

export const ZERO_BIGNUM = new BigNum(0);
export const ZERO_PRICE_BIGNUM = BigNum.from(0, PRICE_PRECISION_EXP);
export const ZERO = new BN(0);
export const HUNDRED = new BN(100);

// Prediction Markets
export const MAX_PREDICTION_PRICE_NUM =
	MAX_PREDICTION_PRICE.div(PRICE_PRECISION).toNumber();
export const MAX_PREDICTION_PRICE_BIGNUM = BigNum.from(
	MAX_PREDICTION_PRICE,
	PRICE_PRECISION_EXP
);
export const PREDICTION_MARKET_AUCTION_PRICE_CAPS = {
	min: ZERO,
	max: MAX_PREDICTION_PRICE,
};

// Time
export const ONE_MINUTE_MS = 60 * 1000;
export const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;
export const ONE_DAY_MS = 24 * ONE_HOUR_MS;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.digestSignature = exports.digest = void 0;
const crypto_1 = require("crypto");
function digest(data) {
    const hash = (0, crypto_1.createHash)('sha256');
    hash.update(data);
    return hash.digest();
}
exports.digest = digest;
function digestSignature(signature) {
    return (0, crypto_1.createHash)('sha256').update(signature).digest('base64');
}
exports.digestSignature = digestSignature;

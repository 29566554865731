import { Connection } from '@solana/web3.js';
import { shareReplay } from 'rxjs/operators';
import { createTokenBalanceObservable } from 'src/providers/optimisedDataSubscriptions/splTokenBalanceObservable';
import { Observable, BehaviorSubject } from 'rxjs';

interface SubscriptionRecipe<T> {
	getObservable: () => Observable<T>;
}

type OptimisedObservable<T> = Observable<T>;

/**
 * ⭐️ Important
 *
 * This function is the crux of creating an "optimised observable". Most of the work is done in rxjs with the shareReplay operator and config we're using below.
 *
 * rxjs config explained:
 * - shareReplay: The shareReplay operator is used to share a single subscription among multiple subscribers.
 * - bufferSize=1: This lets us store the last emitted value (e.g. new secondary subscribers won't get null or undefined at the beginning while they wait for the next tick, they'll get whatever the last shared value was). We use size=1 because we only need the last emitted value, no further history.
 * - refCount=true: This ensures that the observable is only subscribed to when there is at least one subscriber, hence the "work" being done inside of the observable stops when there are no active subscribers.
 *
 * @param id
 * @param recipe
 * @returns
 */
const createOptimisedObservable = <T>(
	id: string,
	recipe: SubscriptionRecipe<T>
): OptimisedObservable<T> => {
	const observable = recipe.getObservable().pipe(
		shareReplay({
			bufferSize: 1,
			refCount: true,
		})
	);

	return observable;
};

let devTestValue = 0;

export const createTestObservable = (): Observable<number> => {
	const devTestValueSubject = new BehaviorSubject<number | undefined>(
		undefined
	);

	let startValue = devTestValue++;

	const observable = new Observable<number>((subscriber) => {
		const subscription = devTestValueSubject.subscribe(subscriber);

		devTestValueSubject.next(startValue);

		const interval = setInterval(() => {
			devTestValueSubject.next(startValue++);
		}, 2_000);

		// Return the teardown logic
		return () => {
			subscription.unsubscribe();

			clearInterval(interval);
		};
	});

	return observable;
};

// SubscriptionProvider class
export class SubscriptionProviderCore {
	private subscriptions: Map<string, OptimisedObservable<any>> = new Map();

	// Generic method to get or create a subscription
	private getOrCreateSubscription<T>(
		id: string,
		recipe: SubscriptionRecipe<T>
	): OptimisedObservable<T> {
		if (!this.subscriptions.has(id)) {
			this.subscriptions.set(id, createOptimisedObservable(id, recipe));
		}
		return this.subscriptions.get(id) as OptimisedObservable<T>;
	}

	getTokenBalanceObservable(
		tokenAccountAddress: string,
		connection: Connection
	): Observable<number> {
		const id = `tokenBalance-${tokenAccountAddress}`;

		const recipe: SubscriptionRecipe<number> = {
			getObservable: () =>
				createTokenBalanceObservable(tokenAccountAddress, connection),
		};

		const OptimisedObservable = this.getOrCreateSubscription(id, recipe);

		return OptimisedObservable;
	}

	getDevTestObservable(id: string): Observable<number> {
		const recipe: SubscriptionRecipe<number> = {
			getObservable: createTestObservable,
		};

		const OptimisedObservable = this.getOrCreateSubscription(id, recipe);

		return OptimisedObservable;
	}
}

import React, { PropsWithChildren } from 'react';
import { SubscriptionProviderCore } from 'src/providers/optimisedDataSubscriptions/optimisedSubscriptionsCore';

const INITIAL_CONTEXT_VALUE: {
	subscriptionProvider: SubscriptionProviderCore;
} = {
	subscriptionProvider: new SubscriptionProviderCore(),
};

export const OptimisedSubscriptionsContext = React.createContext(
	INITIAL_CONTEXT_VALUE
);

const OptimisedSubscriptionsProvider = (props: PropsWithChildren<any>) => {
	const contextValue = INITIAL_CONTEXT_VALUE;

	return (
		<OptimisedSubscriptionsContext value={contextValue}>
			{props.children}
		</OptimisedSubscriptionsContext>
	);
};

export default OptimisedSubscriptionsProvider;

'use client';

import { useState } from 'react';
import useInterval from './useInterval';
import { singletonHook } from 'react-singleton-hook';

export const safeCheckDevSwitchIsOn = () => {
	try {
		return window?.localStorage?.getItem('devswitch') === 'true';
	} catch (e) {
		return false;
	}
};

const useDevSwitchIsOn = () => {
	const [devSwitchIsOn, setDevSwitchIsOn] = useState(false);

	useInterval(() => {
		setDevSwitchIsOn(safeCheckDevSwitchIsOn());
	}, 5000);

	return devSwitchIsOn;
};

export default singletonHook(false, useDevSwitchIsOn);

'use client';

import { useEffect, useRef, useState } from 'react';

export function useEffectAfterTimeout(effect, timeout) {
	useEffect(() => {
		const handle = setTimeout(effect, timeout);
		return () => clearTimeout(handle);
	});
}

export function useListener(emitter, eventName) {
	const [, forceUpdate] = useState(0);
	useEffect(() => {
		const listener = () => forceUpdate((i) => i + 1);
		emitter.on(eventName, listener);
		return () => emitter.removeListener(eventName, listener);
	}, [emitter, eventName]);
}

export default function useInterval(
	callback,
	delayMs: number,
	runOnCallbackChange = false
) {
	const savedCallback = useRef<() => void>(null);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
		runOnCallbackChange && savedCallback.current();
	}, [callback, runOnCallbackChange]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current && savedCallback.current();
		}

		tick();

		if (delayMs !== null) {
			const id = setInterval(tick, delayMs);
			return () => {
				clearInterval(id);
			};
		}
	}, [delayMs]);
}

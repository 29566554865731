"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DelistedMarketSetting = exports.NotSubscribedError = void 0;
class NotSubscribedError extends Error {
    constructor() {
        super(...arguments);
        this.name = 'NotSubscribedError';
    }
}
exports.NotSubscribedError = NotSubscribedError;
var DelistedMarketSetting;
(function (DelistedMarketSetting) {
    DelistedMarketSetting[DelistedMarketSetting["Unsubscribe"] = 0] = "Unsubscribe";
    DelistedMarketSetting[DelistedMarketSetting["Subscribe"] = 1] = "Subscribe";
    DelistedMarketSetting[DelistedMarketSetting["Discard"] = 2] = "Discard";
})(DelistedMarketSetting = exports.DelistedMarketSetting || (exports.DelistedMarketSetting = {}));

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.grpcAccountSubscriber = void 0;
const web3_js_1 = require("@solana/web3.js");
const Buffer = __importStar(require("buffer"));
const webSocketAccountSubscriber_1 = require("./webSocketAccountSubscriber");
const grpc_1 = require("../isomorphic/grpc");
class grpcAccountSubscriber extends webSocketAccountSubscriber_1.WebSocketAccountSubscriber {
    constructor(grpcConfigs, accountName, program, accountPublicKey, decodeBuffer, resubOpts) {
        var _a, _b;
        super(accountName, program, accountPublicKey, decodeBuffer, resubOpts);
        this.client = (0, grpc_1.createClient)(grpcConfigs.endpoint, grpcConfigs.token, (_a = grpcConfigs.channelOptions) !== null && _a !== void 0 ? _a : {});
        this.commitmentLevel =
            // @ts-ignore :: isomorphic exported enum fails typescript but will work at runtime
            (_b = grpcConfigs.commitmentLevel) !== null && _b !== void 0 ? _b : grpc_1.CommitmentLevel.CONFIRMED;
    }
    async subscribe(onChange) {
        if (this.listenerId != null || this.isUnsubscribing) {
            return;
        }
        this.onChange = onChange;
        if (!this.dataAndSlot) {
            await this.fetch();
        }
        // Subscribe with grpc
        this.stream = await this.client.subscribe();
        const request = {
            slots: {},
            accounts: {
                account: {
                    account: [this.accountPublicKey.toString()],
                    owner: [],
                    filters: [],
                },
            },
            transactions: {},
            blocks: {},
            blocksMeta: {},
            accountsDataSlice: [],
            commitment: this.commitmentLevel,
            entry: {},
            transactionsStatus: {},
        };
        this.stream.on('data', (chunk) => {
            var _a;
            if (!chunk.account) {
                return;
            }
            const slot = Number(chunk.account.slot);
            const accountInfo = {
                owner: new web3_js_1.PublicKey(chunk.account.account.owner),
                lamports: Number(chunk.account.account.lamports),
                data: Buffer.Buffer.from(chunk.account.account.data),
                executable: chunk.account.account.executable,
                rentEpoch: Number(chunk.account.account.rentEpoch),
            };
            if ((_a = this.resubOpts) === null || _a === void 0 ? void 0 : _a.resubTimeoutMs) {
                this.receivingData = true;
                clearTimeout(this.timeoutId);
                this.handleRpcResponse({
                    slot,
                }, accountInfo);
                this.setTimeout();
            }
            else {
                this.handleRpcResponse({
                    slot,
                }, accountInfo);
            }
        });
        return new Promise((resolve, reject) => {
            this.stream.write(request, (err) => {
                var _a;
                if (err === null || err === undefined) {
                    this.listenerId = 1;
                    if ((_a = this.resubOpts) === null || _a === void 0 ? void 0 : _a.resubTimeoutMs) {
                        this.receivingData = true;
                        this.setTimeout();
                    }
                    resolve();
                }
                else {
                    reject(err);
                }
            });
        }).catch((reason) => {
            console.error(reason);
            throw reason;
        });
    }
    async unsubscribe(onResub = false) {
        if (!onResub && this.resubOpts) {
            this.resubOpts.resubTimeoutMs = undefined;
        }
        this.isUnsubscribing = true;
        clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
        if (this.listenerId != null) {
            const promise = new Promise((resolve, reject) => {
                const request = {
                    slots: {},
                    accounts: {},
                    transactions: {},
                    blocks: {},
                    blocksMeta: {},
                    accountsDataSlice: [],
                    entry: {},
                    transactionsStatus: {},
                };
                this.stream.write(request, (err) => {
                    if (err === null || err === undefined) {
                        this.listenerId = undefined;
                        this.isUnsubscribing = false;
                        resolve();
                    }
                    else {
                        reject(err);
                    }
                });
            }).catch((reason) => {
                console.error(reason);
                throw reason;
            });
            return promise;
        }
        else {
            this.isUnsubscribing = false;
        }
    }
}
exports.grpcAccountSubscriber = grpcAccountSubscriber;

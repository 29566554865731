'use client';

import React, { MouseEventHandler, PropsWithChildren, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export type TextProps = PropsWithChildren<{
	className?: string;
	onClick?: MouseEventHandler<HTMLSpanElement>;
	style?: React.CSSProperties;
}>;

const Text = (props: TextProps) => {
	return (
		<span
			onClick={props.onClick}
			className={props.className}
			style={props.style}
		>
			{props.children}
		</span>
	);
};

const XXL = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[300] text-[32px] leading-[44px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.XXL = XXL;

const XL = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[300] text-[24px] leading-[36px]`, props.className),
		[props.className]
	);
	return <Text {...props} className={memoedClassName} />;
};
Text.XL = XL;

const H1 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[400] text-[20px] leading-[24px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};

Text.H1 = H1;

const H2 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() =>
			twMerge(
				`font-[300] text-[18px] leading-[22px] font-medium`,
				props.className
			),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.H2 = H2;

const H3 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[400] text-[16px] leading-[-0.25px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.H3 = H3;

const H4 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[300] text-[14px] leading-[18px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.H4 = H4;

const H5 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[400] text-[13px] leading-[18px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.H5 = H5;

const BODY1 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[300] text-[14px] leading-[16px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.BODY1 = BODY1;

const BODY2 = (props: TextProps & { light?: boolean }) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[300] text-[13px] leading-[16px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.BODY2 = BODY2;

const BODY3 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() =>
			twMerge(
				`font-[300] text-[12px] leading-[14px] tracking-[0.15px]`,
				props.className
			),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.BODY3 = BODY3;

const P1 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[300] text-[13px] leading-[18px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.P1 = P1;

const MICRO1 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() =>
			twMerge(
				`font-[400] text-[11px] leading-[12px] tracking-[.15px]`,
				props.className
			),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.MICRO1 = MICRO1;

const MICRO2 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() =>
			twMerge(
				`font-[400] text-[8px] leading-[10px] tracking-[.15px]`,
				props.className
			),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.MICRO2 = MICRO2;

const MICRO3 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[400] text-[10px] leading-[12px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.MICRO3 = MICRO3;

const MICRO4 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[400] text-[10px] leading-[11px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.MICRO4 = MICRO4;

const INPUTLABEL01 = (props: TextProps) => {
	const memoedClassName = useMemo(
		() => twMerge(`font-[400] text-[12px] leading-[16px]`, props.className),
		[props.className]
	);

	return <Text {...props} className={memoedClassName} />;
};
Text.INPUTLABEL01 = INPUTLABEL01;

const TITLE4 = ({ className, ...props }: TextProps) => {
	return (
		<Text
			{...props}
			className={twMerge('text-[13px] leading-[16px] font-medium', className)}
		/>
	);
};
Text.TITLE4 = TITLE4;

const TITLE5 = ({ className, ...props }: TextProps) => {
	return (
		<Text
			{...props}
			className={twMerge('text-[12px] leading-[16px] font-medium', className)}
		/>
	);
};
Text.TITLE5 = TITLE5;

export default Text;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketHighLeverageModeConfigAccountSubscriber = void 0;
const types_1 = require("./types");
const events_1 = require("events");
const webSocketAccountSubscriber_1 = require("./webSocketAccountSubscriber");
class WebSocketHighLeverageModeConfigAccountSubscriber {
    constructor(program, highLeverageModeConfigAccountPublicKey, resubTimeoutMs, commitment) {
        this.isSubscribed = false;
        this.program = program;
        this.highLeverageModeConfigAccountPublicKey =
            highLeverageModeConfigAccountPublicKey;
        this.eventEmitter = new events_1.EventEmitter();
        this.resubTimeoutMs = resubTimeoutMs;
        this.commitment = commitment;
    }
    async subscribe(highLeverageModeConfigAccount) {
        if (this.isSubscribed) {
            return true;
        }
        this.highLeverageModeConfigDataAccountSubscriber =
            new webSocketAccountSubscriber_1.WebSocketAccountSubscriber('highLeverageModeConfig', this.program, this.highLeverageModeConfigAccountPublicKey, undefined, {
                resubTimeoutMs: this.resubTimeoutMs,
            }, this.commitment);
        if (highLeverageModeConfigAccount) {
            this.highLeverageModeConfigDataAccountSubscriber.setData(highLeverageModeConfigAccount);
        }
        await this.highLeverageModeConfigDataAccountSubscriber.subscribe((data) => {
            this.eventEmitter.emit('highLeverageModeConfigAccountUpdate', data);
            this.eventEmitter.emit('update');
        });
        this.eventEmitter.emit('update');
        this.isSubscribed = true;
        return true;
    }
    async fetch() {
        await Promise.all([
            this.highLeverageModeConfigDataAccountSubscriber.fetch(),
        ]);
    }
    async unsubscribe() {
        if (!this.isSubscribed) {
            return;
        }
        await Promise.all([
            this.highLeverageModeConfigDataAccountSubscriber.unsubscribe(),
        ]);
        this.isSubscribed = false;
    }
    assertIsSubscribed() {
        if (!this.isSubscribed) {
            throw new types_1.NotSubscribedError('You must call `subscribe` before using this function');
        }
    }
    getHighLeverageModeConfigAccountAndSlot() {
        this.assertIsSubscribed();
        return this.highLeverageModeConfigDataAccountSubscriber.dataAndSlot;
    }
    updateData(highLeverageModeConfig, slot) {
        var _a;
        const currentDataSlot = ((_a = this.highLeverageModeConfigDataAccountSubscriber.dataAndSlot) === null || _a === void 0 ? void 0 : _a.slot) || 0;
        if (currentDataSlot <= slot) {
            this.highLeverageModeConfigDataAccountSubscriber.setData(highLeverageModeConfig, slot);
            this.eventEmitter.emit('highLeverageModeConfigAccountUpdate', highLeverageModeConfig);
            this.eventEmitter.emit('update');
        }
    }
}
exports.WebSocketHighLeverageModeConfigAccountSubscriber = WebSocketHighLeverageModeConfigAccountSubscriber;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminClient = void 0;
const web3_js_1 = require("@solana/web3.js");
const types_1 = require("./types");
const userName_1 = require("./userName");
const anchor_1 = require("@coral-xyz/anchor");
const anchor = __importStar(require("@coral-xyz/anchor"));
const pda_1 = require("./addresses/pda");
const utils_1 = require("./math/utils");
const spl_token_1 = require("@solana/spl-token");
const driftClient_1 = require("./driftClient");
const numericConstants_1 = require("./constants/numericConstants");
const trade_1 = require("./math/trade");
const amm_1 = require("./math/amm");
const phoenix_sdk_1 = require("@ellipsis-labs/phoenix-sdk");
const config_1 = require("./config");
const pythPullOracleUtils_1 = require("./util/pythPullOracleUtils");
const OPENBOOK_PROGRAM_ID = new web3_js_1.PublicKey('opnb2LAfJYbRMAHHvqjCwQxanZn7ReEHp1k81EohpZb');
class AdminClient extends driftClient_1.DriftClient {
    async initialize(usdcMint, _adminControlsPrices) {
        const stateAccountRPCResponse = await this.connection.getParsedAccountInfo(await this.getStatePublicKey());
        if (stateAccountRPCResponse.value !== null) {
            throw new Error('Clearing house already initialized');
        }
        const [driftStatePublicKey] = await (0, pda_1.getDriftStateAccountPublicKeyAndNonce)(this.program.programId);
        const initializeIx = await this.program.instruction.initialize({
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: driftStatePublicKey,
                quoteAssetMint: usdcMint,
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                driftSigner: this.getSignerPublicKey(),
                systemProgram: anchor.web3.SystemProgram.programId,
                tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
            },
        });
        const tx = await this.buildTransaction(initializeIx);
        const { txSig } = await super.sendTransaction(tx, [], this.opts);
        return [txSig];
    }
    async initializeSpotMarket(mint, optimalUtilization, optimalRate, maxRate, oracle, oracleSource, initialAssetWeight, maintenanceAssetWeight, initialLiabilityWeight, maintenanceLiabilityWeight, imfFactor = 0, liquidatorFee = 0, ifLiquidationFee = 0, activeStatus = true, assetTier = types_1.AssetTier.COLLATERAL, scaleInitialAssetWeightStart = numericConstants_1.ZERO, withdrawGuardThreshold = numericConstants_1.ZERO, orderTickSize = numericConstants_1.ONE, orderStepSize = numericConstants_1.ONE, ifTotalFactor = 0, name = userName_1.DEFAULT_MARKET_NAME, marketIndex) {
        const spotMarketIndex = marketIndex !== null && marketIndex !== void 0 ? marketIndex : this.getStateAccount().numberOfSpotMarkets;
        const initializeIx = await this.getInitializeSpotMarketIx(mint, optimalUtilization, optimalRate, maxRate, oracle, oracleSource, initialAssetWeight, maintenanceAssetWeight, initialLiabilityWeight, maintenanceLiabilityWeight, imfFactor, liquidatorFee, ifLiquidationFee, activeStatus, assetTier, scaleInitialAssetWeightStart, withdrawGuardThreshold, orderTickSize, orderStepSize, ifTotalFactor, name, marketIndex);
        const tx = await this.buildTransaction(initializeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        await this.accountSubscriber.addSpotMarket(spotMarketIndex);
        await this.accountSubscriber.addOracle({
            source: oracleSource,
            publicKey: oracle,
        });
        await this.accountSubscriber.setSpotOracleMap();
        return txSig;
    }
    async getInitializeSpotMarketIx(mint, optimalUtilization, optimalRate, maxRate, oracle, oracleSource, initialAssetWeight, maintenanceAssetWeight, initialLiabilityWeight, maintenanceLiabilityWeight, imfFactor = 0, liquidatorFee = 0, ifLiquidationFee = 0, activeStatus = true, assetTier = types_1.AssetTier.COLLATERAL, scaleInitialAssetWeightStart = numericConstants_1.ZERO, withdrawGuardThreshold = numericConstants_1.ZERO, orderTickSize = numericConstants_1.ONE, orderStepSize = numericConstants_1.ONE, ifTotalFactor = 0, name = userName_1.DEFAULT_MARKET_NAME, marketIndex) {
        const spotMarketIndex = marketIndex !== null && marketIndex !== void 0 ? marketIndex : this.getStateAccount().numberOfSpotMarkets;
        const spotMarket = await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex);
        const spotMarketVault = await (0, pda_1.getSpotMarketVaultPublicKey)(this.program.programId, spotMarketIndex);
        const insuranceFundVault = await (0, pda_1.getInsuranceFundVaultPublicKey)(this.program.programId, spotMarketIndex);
        const tokenProgram = (await this.connection.getAccountInfo(mint)).owner;
        const nameBuffer = (0, userName_1.encodeName)(name);
        const initializeIx = await this.program.instruction.initializeSpotMarket(optimalUtilization, optimalRate, maxRate, oracleSource, initialAssetWeight, maintenanceAssetWeight, initialLiabilityWeight, maintenanceLiabilityWeight, imfFactor, liquidatorFee, ifLiquidationFee, activeStatus, assetTier, scaleInitialAssetWeightStart, withdrawGuardThreshold, orderTickSize, orderStepSize, ifTotalFactor, nameBuffer, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket,
                spotMarketVault,
                insuranceFundVault,
                driftSigner: this.getSignerPublicKey(),
                spotMarketMint: mint,
                oracle,
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: anchor.web3.SystemProgram.programId,
                tokenProgram,
            },
        });
        return initializeIx;
    }
    async deleteInitializedSpotMarket(marketIndex) {
        const deleteInitializeMarketIx = await this.getDeleteInitializedSpotMarketIx(marketIndex);
        const tx = await this.buildTransaction(deleteInitializeMarketIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getDeleteInitializedSpotMarketIx(marketIndex) {
        const spotMarketPublicKey = await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, marketIndex);
        const spotMarketVaultPublicKey = await (0, pda_1.getSpotMarketVaultPublicKey)(this.program.programId, marketIndex);
        const insuranceFundVaultPublicKey = await (0, pda_1.getInsuranceFundVaultPublicKey)(this.program.programId, marketIndex);
        return await this.program.instruction.deleteInitializedSpotMarket(marketIndex, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                spotMarket: spotMarketPublicKey,
                spotMarketVault: spotMarketVaultPublicKey,
                insuranceFundVault: insuranceFundVaultPublicKey,
                driftSigner: this.getSignerPublicKey(),
                tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
            },
        });
    }
    async initializeSerumFulfillmentConfig(marketIndex, serumMarket, serumProgram) {
        const initializeIx = await this.getInitializeSerumFulfillmentConfigIx(marketIndex, serumMarket, serumProgram);
        const tx = await this.buildTransaction(initializeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitializeSerumFulfillmentConfigIx(marketIndex, serumMarket, serumProgram) {
        const serumOpenOrders = (0, pda_1.getSerumOpenOrdersPublicKey)(this.program.programId, serumMarket);
        const serumFulfillmentConfig = (0, pda_1.getSerumFulfillmentConfigPublicKey)(this.program.programId, serumMarket);
        return await this.program.instruction.initializeSerumFulfillmentConfig(marketIndex, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                baseSpotMarket: this.getSpotMarketAccount(marketIndex).pubkey,
                quoteSpotMarket: this.getQuoteSpotMarketAccount().pubkey,
                driftSigner: this.getSignerPublicKey(),
                serumProgram,
                serumMarket,
                serumOpenOrders,
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: anchor.web3.SystemProgram.programId,
                serumFulfillmentConfig,
            },
        });
    }
    async initializePhoenixFulfillmentConfig(marketIndex, phoenixMarket) {
        const initializeIx = await this.getInitializePhoenixFulfillmentConfigIx(marketIndex, phoenixMarket);
        const tx = await this.buildTransaction(initializeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitializePhoenixFulfillmentConfigIx(marketIndex, phoenixMarket) {
        const phoenixFulfillmentConfig = (0, pda_1.getPhoenixFulfillmentConfigPublicKey)(this.program.programId, phoenixMarket);
        return await this.program.instruction.initializePhoenixFulfillmentConfig(marketIndex, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                baseSpotMarket: this.getSpotMarketAccount(marketIndex).pubkey,
                quoteSpotMarket: this.getQuoteSpotMarketAccount().pubkey,
                driftSigner: this.getSignerPublicKey(),
                phoenixMarket: phoenixMarket,
                phoenixProgram: phoenix_sdk_1.PROGRAM_ID,
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: anchor.web3.SystemProgram.programId,
                phoenixFulfillmentConfig,
            },
        });
    }
    async initializeOpenbookV2FulfillmentConfig(marketIndex, openbookMarket) {
        const initializeIx = await this.getInitializeOpenbookV2FulfillmentConfigIx(marketIndex, openbookMarket);
        const tx = await this.buildTransaction(initializeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitializeOpenbookV2FulfillmentConfigIx(marketIndex, openbookMarket) {
        const openbookFulfillmentConfig = (0, pda_1.getOpenbookV2FulfillmentConfigPublicKey)(this.program.programId, openbookMarket);
        return this.program.instruction.initializeOpenbookV2FulfillmentConfig(marketIndex, {
            accounts: {
                baseSpotMarket: this.getSpotMarketAccount(marketIndex).pubkey,
                quoteSpotMarket: this.getQuoteSpotMarketAccount().pubkey,
                state: await this.getStatePublicKey(),
                openbookV2Program: OPENBOOK_PROGRAM_ID,
                openbookV2Market: openbookMarket,
                driftSigner: this.getSignerPublicKey(),
                openbookV2FulfillmentConfig: openbookFulfillmentConfig,
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: anchor.web3.SystemProgram.programId,
            },
        });
    }
    async initializePerpMarket(marketIndex, priceOracle, baseAssetReserve, quoteAssetReserve, periodicity, pegMultiplier = numericConstants_1.PEG_PRECISION, oracleSource = types_1.OracleSource.PYTH, contractTier = types_1.ContractTier.SPECULATIVE, marginRatioInitial = 2000, marginRatioMaintenance = 500, liquidatorFee = 0, ifLiquidatorFee = 10000, imfFactor = 0, activeStatus = true, baseSpread = 0, maxSpread = 142500, maxOpenInterest = numericConstants_1.ZERO, maxRevenueWithdrawPerPeriod = numericConstants_1.ZERO, quoteMaxInsurance = numericConstants_1.ZERO, orderStepSize = numericConstants_1.BASE_PRECISION.divn(10000), orderTickSize = numericConstants_1.PRICE_PRECISION.divn(100000), minOrderSize = numericConstants_1.BASE_PRECISION.divn(10000), concentrationCoefScale = numericConstants_1.ONE, curveUpdateIntensity = 0, ammJitIntensity = 0, name = userName_1.DEFAULT_MARKET_NAME) {
        const currentPerpMarketIndex = this.getStateAccount().numberOfMarkets;
        const initializeMarketIx = await this.getInitializePerpMarketIx(marketIndex, priceOracle, baseAssetReserve, quoteAssetReserve, periodicity, pegMultiplier, oracleSource, contractTier, marginRatioInitial, marginRatioMaintenance, liquidatorFee, ifLiquidatorFee, imfFactor, activeStatus, baseSpread, maxSpread, maxOpenInterest, maxRevenueWithdrawPerPeriod, quoteMaxInsurance, orderStepSize, orderTickSize, minOrderSize, concentrationCoefScale, curveUpdateIntensity, ammJitIntensity, name);
        const tx = await this.buildTransaction(initializeMarketIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        while (this.getStateAccount().numberOfMarkets <= currentPerpMarketIndex) {
            await this.fetchAccounts();
        }
        await this.accountSubscriber.addPerpMarket(marketIndex);
        await this.accountSubscriber.addOracle({
            source: oracleSource,
            publicKey: priceOracle,
        });
        await this.accountSubscriber.setPerpOracleMap();
        return txSig;
    }
    async getInitializePerpMarketIx(marketIndex, priceOracle, baseAssetReserve, quoteAssetReserve, periodicity, pegMultiplier = numericConstants_1.PEG_PRECISION, oracleSource = types_1.OracleSource.PYTH, contractTier = types_1.ContractTier.SPECULATIVE, marginRatioInitial = 2000, marginRatioMaintenance = 500, liquidatorFee = 0, ifLiquidatorFee = 10000, imfFactor = 0, activeStatus = true, baseSpread = 0, maxSpread = 142500, maxOpenInterest = numericConstants_1.ZERO, maxRevenueWithdrawPerPeriod = numericConstants_1.ZERO, quoteMaxInsurance = numericConstants_1.ZERO, orderStepSize = numericConstants_1.BASE_PRECISION.divn(10000), orderTickSize = numericConstants_1.PRICE_PRECISION.divn(100000), minOrderSize = numericConstants_1.BASE_PRECISION.divn(10000), concentrationCoefScale = numericConstants_1.ONE, curveUpdateIntensity = 0, ammJitIntensity = 0, name = userName_1.DEFAULT_MARKET_NAME) {
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, marketIndex);
        const nameBuffer = (0, userName_1.encodeName)(name);
        return await this.program.instruction.initializePerpMarket(marketIndex, baseAssetReserve, quoteAssetReserve, periodicity, pegMultiplier, oracleSource, contractTier, marginRatioInitial, marginRatioMaintenance, liquidatorFee, ifLiquidatorFee, imfFactor, activeStatus, baseSpread, maxSpread, maxOpenInterest, maxRevenueWithdrawPerPeriod, quoteMaxInsurance, orderStepSize, orderTickSize, minOrderSize, concentrationCoefScale, curveUpdateIntensity, ammJitIntensity, nameBuffer, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                oracle: priceOracle,
                perpMarket: perpMarketPublicKey,
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: anchor.web3.SystemProgram.programId,
            },
        });
    }
    async initializePredictionMarket(perpMarketIndex) {
        const updatePerpMarketConcentrationCoefIx = await this.getInitializePredictionMarketIx(perpMarketIndex);
        const tx = await this.buildTransaction(updatePerpMarketConcentrationCoefIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitializePredictionMarketIx(perpMarketIndex) {
        return await this.program.instruction.initializePredictionMarket({
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async deleteInitializedPerpMarket(marketIndex) {
        const deleteInitializeMarketIx = await this.getDeleteInitializedPerpMarketIx(marketIndex);
        const tx = await this.buildTransaction(deleteInitializeMarketIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getDeleteInitializedPerpMarketIx(marketIndex) {
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, marketIndex);
        return await this.program.instruction.deleteInitializedPerpMarket(marketIndex, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                perpMarket: perpMarketPublicKey,
            },
        });
    }
    async moveAmmPrice(perpMarketIndex, baseAssetReserve, quoteAssetReserve, sqrtK) {
        const moveAmmPriceIx = await this.getMoveAmmPriceIx(perpMarketIndex, baseAssetReserve, quoteAssetReserve, sqrtK);
        const tx = await this.buildTransaction(moveAmmPriceIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getMoveAmmPriceIx(perpMarketIndex, baseAssetReserve, quoteAssetReserve, sqrtK) {
        const marketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        if (sqrtK == undefined) {
            sqrtK = (0, utils_1.squareRootBN)(baseAssetReserve.mul(quoteAssetReserve));
        }
        return await this.program.instruction.moveAmmPrice(baseAssetReserve, quoteAssetReserve, sqrtK, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                perpMarket: marketPublicKey,
            },
        });
    }
    async updateK(perpMarketIndex, sqrtK) {
        const updateKIx = await this.getUpdateKIx(perpMarketIndex, sqrtK);
        const tx = await this.buildTransaction(updateKIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateKIx(perpMarketIndex, sqrtK) {
        return await this.program.instruction.updateK(sqrtK, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
                oracle: this.getPerpMarketAccount(perpMarketIndex).amm.oracle,
            },
        });
    }
    async recenterPerpMarketAmm(perpMarketIndex, pegMultiplier, sqrtK) {
        const recenterPerpMarketAmmIx = await this.getRecenterPerpMarketAmmIx(perpMarketIndex, pegMultiplier, sqrtK);
        const tx = await this.buildTransaction(recenterPerpMarketAmmIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getRecenterPerpMarketAmmIx(perpMarketIndex, pegMultiplier, sqrtK) {
        const marketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.recenterPerpMarketAmm(pegMultiplier, sqrtK, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                perpMarket: marketPublicKey,
            },
        });
    }
    async updatePerpMarketConcentrationScale(perpMarketIndex, concentrationScale) {
        const updatePerpMarketConcentrationCoefIx = await this.getUpdatePerpMarketConcentrationScaleIx(perpMarketIndex, concentrationScale);
        const tx = await this.buildTransaction(updatePerpMarketConcentrationCoefIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketConcentrationScaleIx(perpMarketIndex, concentrationScale) {
        return await this.program.instruction.updatePerpMarketConcentrationCoef(concentrationScale, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async moveAmmToPrice(perpMarketIndex, targetPrice) {
        const moveAmmPriceIx = await this.getMoveAmmToPriceIx(perpMarketIndex, targetPrice);
        const tx = await this.buildTransaction(moveAmmPriceIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getMoveAmmToPriceIx(perpMarketIndex, targetPrice) {
        const perpMarket = this.getPerpMarketAccount(perpMarketIndex);
        const [direction, tradeSize, _] = (0, trade_1.calculateTargetPriceTrade)(perpMarket, targetPrice, new anchor_1.BN(1000), 'quote', undefined //todo
        );
        const [newQuoteAssetAmount, newBaseAssetAmount] = (0, amm_1.calculateAmmReservesAfterSwap)(perpMarket.amm, 'quote', tradeSize, (0, amm_1.getSwapDirection)('quote', direction));
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.moveAmmPrice(newBaseAssetAmount, newQuoteAssetAmount, perpMarket.amm.sqrtK, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                perpMarket: perpMarketPublicKey,
            },
        });
    }
    async repegAmmCurve(newPeg, perpMarketIndex) {
        const repegAmmCurveIx = await this.getRepegAmmCurveIx(newPeg, perpMarketIndex);
        const tx = await this.buildTransaction(repegAmmCurveIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getRepegAmmCurveIx(newPeg, perpMarketIndex) {
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        const ammData = this.getPerpMarketAccount(perpMarketIndex).amm;
        return await this.program.instruction.repegAmmCurve(newPeg, {
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                oracle: ammData.oracle,
                perpMarket: perpMarketPublicKey,
            },
        });
    }
    async updatePerpMarketAmmOracleTwap(perpMarketIndex) {
        const updatePerpMarketAmmOracleTwapIx = await this.getUpdatePerpMarketAmmOracleTwapIx(perpMarketIndex);
        const tx = await this.buildTransaction(updatePerpMarketAmmOracleTwapIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketAmmOracleTwapIx(perpMarketIndex) {
        const ammData = this.getPerpMarketAccount(perpMarketIndex).amm;
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.updatePerpMarketAmmOracleTwap({
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                oracle: ammData.oracle,
                perpMarket: perpMarketPublicKey,
            },
        });
    }
    async resetPerpMarketAmmOracleTwap(perpMarketIndex) {
        const resetPerpMarketAmmOracleTwapIx = await this.getResetPerpMarketAmmOracleTwapIx(perpMarketIndex);
        const tx = await this.buildTransaction(resetPerpMarketAmmOracleTwapIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getResetPerpMarketAmmOracleTwapIx(perpMarketIndex) {
        const ammData = this.getPerpMarketAccount(perpMarketIndex).amm;
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.resetPerpMarketAmmOracleTwap({
            accounts: {
                state: await this.getStatePublicKey(),
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                oracle: ammData.oracle,
                perpMarket: perpMarketPublicKey,
            },
        });
    }
    async depositIntoPerpMarketFeePool(perpMarketIndex, amount, sourceVault) {
        const depositIntoPerpMarketFeePoolIx = await this.getDepositIntoPerpMarketFeePoolIx(perpMarketIndex, amount, sourceVault);
        const tx = await this.buildTransaction(depositIntoPerpMarketFeePoolIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getDepositIntoPerpMarketFeePoolIx(perpMarketIndex, amount, sourceVault) {
        const spotMarket = this.getQuoteSpotMarketAccount();
        return await this.program.instruction.depositIntoPerpMarketFeePool(amount, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
                sourceVault,
                driftSigner: this.getSignerPublicKey(),
                quoteSpotMarket: spotMarket.pubkey,
                spotMarketVault: spotMarket.vault,
                tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
            },
        });
    }
    async depositIntoSpotMarketVault(spotMarketIndex, amount, sourceVault) {
        const depositIntoPerpMarketFeePoolIx = await this.getDepositIntoSpotMarketVaultIx(spotMarketIndex, amount, sourceVault);
        const tx = await this.buildTransaction(depositIntoPerpMarketFeePoolIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getDepositIntoSpotMarketVaultIx(spotMarketIndex, amount, sourceVault) {
        const spotMarket = this.getSpotMarketAccount(spotMarketIndex);
        const remainingAccounts = [];
        this.addTokenMintToRemainingAccounts(spotMarket, remainingAccounts);
        const tokenProgram = this.getTokenProgramForSpotMarket(spotMarket);
        return await this.program.instruction.depositIntoSpotMarketVault(amount, {
            accounts: {
                admin: this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                sourceVault,
                spotMarket: spotMarket.pubkey,
                spotMarketVault: spotMarket.vault,
                tokenProgram,
            },
            remainingAccounts,
        });
    }
    async updateAdmin(admin) {
        const updateAdminIx = await this.getUpdateAdminIx(admin);
        const tx = await this.buildTransaction(updateAdminIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateAdminIx(admin) {
        return await this.program.instruction.updateAdmin(admin, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updatePerpMarketCurveUpdateIntensity(perpMarketIndex, curveUpdateIntensity) {
        const updatePerpMarketCurveUpdateIntensityIx = await this.getUpdatePerpMarketCurveUpdateIntensityIx(perpMarketIndex, curveUpdateIntensity);
        const tx = await this.buildTransaction(updatePerpMarketCurveUpdateIntensityIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketCurveUpdateIntensityIx(perpMarketIndex, curveUpdateIntensity) {
        return await this.program.instruction.updatePerpMarketCurveUpdateIntensity(curveUpdateIntensity, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketTargetBaseAssetAmountPerLp(perpMarketIndex, targetBaseAssetAmountPerLP) {
        const updatePerpMarketTargetBaseAssetAmountPerLpIx = await this.getUpdatePerpMarketTargetBaseAssetAmountPerLpIx(perpMarketIndex, targetBaseAssetAmountPerLP);
        const tx = await this.buildTransaction(updatePerpMarketTargetBaseAssetAmountPerLpIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async updatePerpMarketAmmSummaryStats(perpMarketIndex, updateAmmSummaryStats, quoteAssetAmountWithUnsettledLp, netUnsettledFundingPnl) {
        const updatePerpMarketMarginRatioIx = await this.getUpdatePerpMarketAmmSummaryStatsIx(perpMarketIndex, updateAmmSummaryStats, quoteAssetAmountWithUnsettledLp, netUnsettledFundingPnl);
        const tx = await this.buildTransaction(updatePerpMarketMarginRatioIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketAmmSummaryStatsIx(perpMarketIndex, updateAmmSummaryStats, quoteAssetAmountWithUnsettledLp, netUnsettledFundingPnl) {
        return await this.program.instruction.updatePerpMarketAmmSummaryStats({
            updateAmmSummaryStats: updateAmmSummaryStats !== null && updateAmmSummaryStats !== void 0 ? updateAmmSummaryStats : null,
            quoteAssetAmountWithUnsettledLp: quoteAssetAmountWithUnsettledLp !== null && quoteAssetAmountWithUnsettledLp !== void 0 ? quoteAssetAmountWithUnsettledLp : null,
            netUnsettledFundingPnl: netUnsettledFundingPnl !== null && netUnsettledFundingPnl !== void 0 ? netUnsettledFundingPnl : null,
        }, {
            accounts: {
                admin: this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, numericConstants_1.QUOTE_SPOT_MARKET_INDEX),
                oracle: this.getPerpMarketAccount(perpMarketIndex).amm.oracle,
            },
        });
    }
    async getUpdatePerpMarketTargetBaseAssetAmountPerLpIx(perpMarketIndex, targetBaseAssetAmountPerLP) {
        return await this.program.instruction.updatePerpMarketTargetBaseAssetAmountPerLp(targetBaseAssetAmountPerLP, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketMarginRatio(perpMarketIndex, marginRatioInitial, marginRatioMaintenance) {
        const updatePerpMarketMarginRatioIx = await this.getUpdatePerpMarketMarginRatioIx(perpMarketIndex, marginRatioInitial, marginRatioMaintenance);
        const tx = await this.buildTransaction(updatePerpMarketMarginRatioIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketMarginRatioIx(perpMarketIndex, marginRatioInitial, marginRatioMaintenance) {
        return await this.program.instruction.updatePerpMarketMarginRatio(marginRatioInitial, marginRatioMaintenance, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketHighLeverageMarginRatio(perpMarketIndex, marginRatioInitial, marginRatioMaintenance) {
        const updatePerpMarketHighLeverageMarginRatioIx = await this.getUpdatePerpMarketHighLeverageMarginRatioIx(perpMarketIndex, marginRatioInitial, marginRatioMaintenance);
        const tx = await this.buildTransaction(updatePerpMarketHighLeverageMarginRatioIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketHighLeverageMarginRatioIx(perpMarketIndex, marginRatioInitial, marginRatioMaintenance) {
        return await this.program.instruction.updatePerpMarketHighLeverageMarginRatio(marginRatioInitial, marginRatioMaintenance, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketImfFactor(perpMarketIndex, imfFactor, unrealizedPnlImfFactor) {
        const updatePerpMarketImfFactorIx = await this.getUpdatePerpMarketImfFactorIx(perpMarketIndex, imfFactor, unrealizedPnlImfFactor);
        const tx = await this.buildTransaction(updatePerpMarketImfFactorIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketImfFactorIx(perpMarketIndex, imfFactor, unrealizedPnlImfFactor) {
        return await this.program.instruction.updatePerpMarketImfFactor(imfFactor, unrealizedPnlImfFactor, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketBaseSpread(perpMarketIndex, baseSpread) {
        const updatePerpMarketBaseSpreadIx = await this.getUpdatePerpMarketBaseSpreadIx(perpMarketIndex, baseSpread);
        const tx = await this.buildTransaction(updatePerpMarketBaseSpreadIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketBaseSpreadIx(perpMarketIndex, baseSpread) {
        return await this.program.instruction.updatePerpMarketBaseSpread(baseSpread, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateAmmJitIntensity(perpMarketIndex, ammJitIntensity) {
        const updateAmmJitIntensityIx = await this.getUpdateAmmJitIntensityIx(perpMarketIndex, ammJitIntensity);
        const tx = await this.buildTransaction(updateAmmJitIntensityIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateAmmJitIntensityIx(perpMarketIndex, ammJitIntensity) {
        return await this.program.instruction.updateAmmJitIntensity(ammJitIntensity, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketName(perpMarketIndex, name) {
        const updatePerpMarketNameIx = await this.getUpdatePerpMarketNameIx(perpMarketIndex, name);
        const tx = await this.buildTransaction(updatePerpMarketNameIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketNameIx(perpMarketIndex, name) {
        const nameBuffer = (0, userName_1.encodeName)(name);
        return await this.program.instruction.updatePerpMarketName(nameBuffer, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateSpotMarketName(spotMarketIndex, name) {
        const updateSpotMarketNameIx = await this.getUpdateSpotMarketNameIx(spotMarketIndex, name);
        const tx = await this.buildTransaction(updateSpotMarketNameIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketNameIx(spotMarketIndex, name) {
        const nameBuffer = (0, userName_1.encodeName)(name);
        return await this.program.instruction.updateSpotMarketName(nameBuffer, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updatePerpMarketPerLpBase(perpMarketIndex, perLpBase) {
        const updatePerpMarketPerLpBaseIx = await this.getUpdatePerpMarketPerLpBaseIx(perpMarketIndex, perLpBase);
        const tx = await this.buildTransaction(updatePerpMarketPerLpBaseIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketPerLpBaseIx(perpMarketIndex, perLpBase) {
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.updatePerpMarketPerLpBase(perLpBase, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: perpMarketPublicKey,
            },
        });
    }
    async updatePerpMarketMaxSpread(perpMarketIndex, maxSpread) {
        const updatePerpMarketMaxSpreadIx = await this.getUpdatePerpMarketMaxSpreadIx(perpMarketIndex, maxSpread);
        const tx = await this.buildTransaction(updatePerpMarketMaxSpreadIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketMaxSpreadIx(perpMarketIndex, maxSpread) {
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.updatePerpMarketMaxSpread(maxSpread, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: perpMarketPublicKey,
            },
        });
    }
    async updatePerpFeeStructure(feeStructure) {
        const updatePerpFeeStructureIx = await this.getUpdatePerpFeeStructureIx(feeStructure);
        const tx = await this.buildTransaction(updatePerpFeeStructureIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpFeeStructureIx(feeStructure) {
        return this.program.instruction.updatePerpFeeStructure(feeStructure, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateSpotFeeStructure(feeStructure) {
        const updateSpotFeeStructureIx = await this.getUpdateSpotFeeStructureIx(feeStructure);
        const tx = await this.buildTransaction(updateSpotFeeStructureIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotFeeStructureIx(feeStructure) {
        return await this.program.instruction.updateSpotFeeStructure(feeStructure, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateInitialPctToLiquidate(initialPctToLiquidate) {
        const updateInitialPctToLiquidateIx = await this.getUpdateInitialPctToLiquidateIx(initialPctToLiquidate);
        const tx = await this.buildTransaction(updateInitialPctToLiquidateIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateInitialPctToLiquidateIx(initialPctToLiquidate) {
        return await this.program.instruction.updateInitialPctToLiquidate(initialPctToLiquidate, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateLiquidationDuration(liquidationDuration) {
        const updateLiquidationDurationIx = await this.getUpdateLiquidationDurationIx(liquidationDuration);
        const tx = await this.buildTransaction(updateLiquidationDurationIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateLiquidationDurationIx(liquidationDuration) {
        return await this.program.instruction.updateLiquidationDuration(liquidationDuration, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateLiquidationMarginBufferRatio(updateLiquidationMarginBufferRatio) {
        const updateLiquidationMarginBufferRatioIx = await this.getUpdateLiquidationMarginBufferRatioIx(updateLiquidationMarginBufferRatio);
        const tx = await this.buildTransaction(updateLiquidationMarginBufferRatioIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateLiquidationMarginBufferRatioIx(updateLiquidationMarginBufferRatio) {
        return await this.program.instruction.updateLiquidationMarginBufferRatio(updateLiquidationMarginBufferRatio, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateOracleGuardRails(oracleGuardRails) {
        const updateOracleGuardRailsIx = await this.getUpdateOracleGuardRailsIx(oracleGuardRails);
        const tx = await this.buildTransaction(updateOracleGuardRailsIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateOracleGuardRailsIx(oracleGuardRails) {
        return await this.program.instruction.updateOracleGuardRails(oracleGuardRails, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateStateSettlementDuration(settlementDuration) {
        const updateStateSettlementDurationIx = await this.getUpdateStateSettlementDurationIx(settlementDuration);
        const tx = await this.buildTransaction(updateStateSettlementDurationIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateStateSettlementDurationIx(settlementDuration) {
        return await this.program.instruction.updateStateSettlementDuration(settlementDuration, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateStateMaxNumberOfSubAccounts(maxNumberOfSubAccounts) {
        const updateStateMaxNumberOfSubAccountsIx = await this.getUpdateStateMaxNumberOfSubAccountsIx(maxNumberOfSubAccounts);
        const tx = await this.buildTransaction(updateStateMaxNumberOfSubAccountsIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateStateMaxNumberOfSubAccountsIx(maxNumberOfSubAccounts) {
        return await this.program.instruction.updateStateMaxNumberOfSubAccounts(maxNumberOfSubAccounts, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateStateMaxInitializeUserFee(maxInitializeUserFee) {
        const updateStateMaxInitializeUserFeeIx = await this.getUpdateStateMaxInitializeUserFeeIx(maxInitializeUserFee);
        const tx = await this.buildTransaction(updateStateMaxInitializeUserFeeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateStateMaxInitializeUserFeeIx(maxInitializeUserFee) {
        return await this.program.instruction.updateStateMaxInitializeUserFee(maxInitializeUserFee, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateWithdrawGuardThreshold(spotMarketIndex, withdrawGuardThreshold) {
        const updateWithdrawGuardThresholdIx = await this.getUpdateWithdrawGuardThresholdIx(spotMarketIndex, withdrawGuardThreshold);
        const tx = await this.buildTransaction(updateWithdrawGuardThresholdIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateWithdrawGuardThresholdIx(spotMarketIndex, withdrawGuardThreshold) {
        return await this.program.instruction.updateWithdrawGuardThreshold(withdrawGuardThreshold, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketIfFactor(spotMarketIndex, userIfFactor, totalIfFactor) {
        const updateSpotMarketIfFactorIx = await this.getUpdateSpotMarketIfFactorIx(spotMarketIndex, userIfFactor, totalIfFactor);
        const tx = await this.buildTransaction(updateSpotMarketIfFactorIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketIfFactorIx(spotMarketIndex, userIfFactor, totalIfFactor) {
        return await this.program.instruction.updateSpotMarketIfFactor(spotMarketIndex, userIfFactor, totalIfFactor, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketRevenueSettlePeriod(spotMarketIndex, revenueSettlePeriod) {
        const updateSpotMarketRevenueSettlePeriodIx = await this.getUpdateSpotMarketRevenueSettlePeriodIx(spotMarketIndex, revenueSettlePeriod);
        const tx = await this.buildTransaction(updateSpotMarketRevenueSettlePeriodIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketRevenueSettlePeriodIx(spotMarketIndex, revenueSettlePeriod) {
        return await this.program.instruction.updateSpotMarketRevenueSettlePeriod(revenueSettlePeriod, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketMaxTokenDeposits(spotMarketIndex, maxTokenDeposits) {
        const updateSpotMarketMaxTokenDepositsIx = await this.getUpdateSpotMarketMaxTokenDepositsIx(spotMarketIndex, maxTokenDeposits);
        const tx = await this.buildTransaction(updateSpotMarketMaxTokenDepositsIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketMaxTokenDepositsIx(spotMarketIndex, maxTokenDeposits) {
        return this.program.instruction.updateSpotMarketMaxTokenDeposits(maxTokenDeposits, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketMaxTokenBorrows(spotMarketIndex, maxTokenBorrowsFraction) {
        const updateSpotMarketMaxTokenBorrowsIx = await this.getUpdateSpotMarketMaxTokenBorrowsIx(spotMarketIndex, maxTokenBorrowsFraction);
        const tx = await this.buildTransaction(updateSpotMarketMaxTokenBorrowsIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketMaxTokenBorrowsIx(spotMarketIndex, maxTokenBorrowsFraction) {
        return this.program.instruction.updateSpotMarketMaxTokenBorrows(maxTokenBorrowsFraction, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketScaleInitialAssetWeightStart(spotMarketIndex, scaleInitialAssetWeightStart) {
        const updateSpotMarketScaleInitialAssetWeightStartIx = await this.getUpdateSpotMarketScaleInitialAssetWeightStartIx(spotMarketIndex, scaleInitialAssetWeightStart);
        const tx = await this.buildTransaction(updateSpotMarketScaleInitialAssetWeightStartIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketScaleInitialAssetWeightStartIx(spotMarketIndex, scaleInitialAssetWeightStart) {
        return this.program.instruction.updateSpotMarketScaleInitialAssetWeightStart(scaleInitialAssetWeightStart, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateInsuranceFundUnstakingPeriod(spotMarketIndex, insuranceWithdrawEscrowPeriod) {
        const updateInsuranceFundUnstakingPeriodIx = await this.getUpdateInsuranceFundUnstakingPeriodIx(spotMarketIndex, insuranceWithdrawEscrowPeriod);
        const tx = await this.buildTransaction(updateInsuranceFundUnstakingPeriodIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateInsuranceFundUnstakingPeriodIx(spotMarketIndex, insuranceWithdrawEscrowPeriod) {
        return await this.program.instruction.updateInsuranceFundUnstakingPeriod(insuranceWithdrawEscrowPeriod, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateLpCooldownTime(cooldownTime) {
        const updateLpCooldownTimeIx = await this.getUpdateLpCooldownTimeIx(cooldownTime);
        const tx = await this.buildTransaction(updateLpCooldownTimeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateLpCooldownTimeIx(cooldownTime) {
        return await this.program.instruction.updateLpCooldownTime(cooldownTime, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updatePerpMarketOracle(perpMarketIndex, oracle, oracleSource) {
        const updatePerpMarketOracleIx = await this.getUpdatePerpMarketOracleIx(perpMarketIndex, oracle, oracleSource);
        const tx = await this.buildTransaction(updatePerpMarketOracleIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketOracleIx(perpMarketIndex, oracle, oracleSource) {
        return await this.program.instruction.updatePerpMarketOracle(oracle, oracleSource, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
                oracle: oracle,
            },
        });
    }
    async updatePerpMarketStepSizeAndTickSize(perpMarketIndex, stepSize, tickSize) {
        const updatePerpMarketStepSizeAndTickSizeIx = await this.getUpdatePerpMarketStepSizeAndTickSizeIx(perpMarketIndex, stepSize, tickSize);
        const tx = await this.buildTransaction(updatePerpMarketStepSizeAndTickSizeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketStepSizeAndTickSizeIx(perpMarketIndex, stepSize, tickSize) {
        return await this.program.instruction.updatePerpMarketStepSizeAndTickSize(stepSize, tickSize, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketMinOrderSize(perpMarketIndex, orderSize) {
        const updatePerpMarketMinOrderSizeIx = await this.getUpdatePerpMarketMinOrderSizeIx(perpMarketIndex, orderSize);
        const tx = await this.buildTransaction(updatePerpMarketMinOrderSizeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketMinOrderSizeIx(perpMarketIndex, orderSize) {
        return await this.program.instruction.updatePerpMarketMinOrderSize(orderSize, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateSpotMarketStepSizeAndTickSize(spotMarketIndex, stepSize, tickSize) {
        const updateSpotMarketStepSizeAndTickSizeIx = await this.getUpdateSpotMarketStepSizeAndTickSizeIx(spotMarketIndex, stepSize, tickSize);
        const tx = await this.buildTransaction(updateSpotMarketStepSizeAndTickSizeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketStepSizeAndTickSizeIx(spotMarketIndex, stepSize, tickSize) {
        return await this.program.instruction.updateSpotMarketStepSizeAndTickSize(stepSize, tickSize, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketMinOrderSize(spotMarketIndex, orderSize) {
        const updateSpotMarketMinOrderSizeIx = await this.program.instruction.updateSpotMarketMinOrderSize(orderSize, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
        const tx = await this.buildTransaction(updateSpotMarketMinOrderSizeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketMinOrderSizeIx(spotMarketIndex, orderSize) {
        return await this.program.instruction.updateSpotMarketMinOrderSize(orderSize, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updatePerpMarketExpiry(perpMarketIndex, expiryTs) {
        const updatePerpMarketExpiryIx = await this.getUpdatePerpMarketExpiryIx(perpMarketIndex, expiryTs);
        const tx = await this.buildTransaction(updatePerpMarketExpiryIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketExpiryIx(perpMarketIndex, expiryTs) {
        return await this.program.instruction.updatePerpMarketExpiry(expiryTs, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateSpotMarketOracle(spotMarketIndex, oracle, oracleSource) {
        const updateSpotMarketOracleIx = await this.getUpdateSpotMarketOracleIx(spotMarketIndex, oracle, oracleSource);
        const tx = await this.buildTransaction(updateSpotMarketOracleIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketOracleIx(spotMarketIndex, oracle, oracleSource) {
        return await this.program.instruction.updateSpotMarketOracle(oracle, oracleSource, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
                oracle: oracle,
            },
        });
    }
    async updateSpotMarketOrdersEnabled(spotMarketIndex, ordersEnabled) {
        const updateSpotMarketOrdersEnabledIx = await this.getUpdateSpotMarketOrdersEnabledIx(spotMarketIndex, ordersEnabled);
        const tx = await this.buildTransaction(updateSpotMarketOrdersEnabledIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketOrdersEnabledIx(spotMarketIndex, ordersEnabled) {
        return await this.program.instruction.updateSpotMarketOrdersEnabled(ordersEnabled, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketIfPausedOperations(spotMarketIndex, pausedOperations) {
        const updateSpotMarketIfStakingDisabledIx = await this.getUpdateSpotMarketIfPausedOperationsIx(spotMarketIndex, pausedOperations);
        const tx = await this.buildTransaction(updateSpotMarketIfStakingDisabledIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketIfPausedOperationsIx(spotMarketIndex, pausedOperations) {
        return await this.program.instruction.updateSpotMarketIfPausedOperations(pausedOperations, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSerumFulfillmentConfigStatus(serumFulfillmentConfig, status) {
        const updateSerumFulfillmentConfigStatusIx = await this.getUpdateSerumFulfillmentConfigStatusIx(serumFulfillmentConfig, status);
        const tx = await this.buildTransaction(updateSerumFulfillmentConfigStatusIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSerumFulfillmentConfigStatusIx(serumFulfillmentConfig, status) {
        return await this.program.instruction.updateSerumFulfillmentConfigStatus(status, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                serumFulfillmentConfig,
            },
        });
    }
    async updatePhoenixFulfillmentConfigStatus(phoenixFulfillmentConfig, status) {
        const updatePhoenixFulfillmentConfigStatusIx = await this.program.instruction.phoenixFulfillmentConfigStatus(status, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                phoenixFulfillmentConfig,
            },
        });
        const tx = await this.buildTransaction(updatePhoenixFulfillmentConfigStatusIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePhoenixFulfillmentConfigStatusIx(phoenixFulfillmentConfig, status) {
        return await this.program.instruction.phoenixFulfillmentConfigStatus(status, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                phoenixFulfillmentConfig,
            },
        });
    }
    async updateSpotMarketExpiry(spotMarketIndex, expiryTs) {
        const updateSpotMarketExpiryIx = await this.getUpdateSpotMarketExpiryIx(spotMarketIndex, expiryTs);
        const tx = await this.buildTransaction(updateSpotMarketExpiryIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketExpiryIx(spotMarketIndex, expiryTs) {
        return await this.program.instruction.updateSpotMarketExpiry(expiryTs, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateWhitelistMint(whitelistMint) {
        const updateWhitelistMintIx = await this.getUpdateWhitelistMintIx(whitelistMint);
        const tx = await this.buildTransaction(updateWhitelistMintIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateWhitelistMintIx(whitelistMint) {
        return await this.program.instruction.updateWhitelistMint(whitelistMint, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateDiscountMint(discountMint) {
        const updateDiscountMintIx = await this.getUpdateDiscountMintIx(discountMint);
        const tx = await this.buildTransaction(updateDiscountMintIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateDiscountMintIx(discountMint) {
        return await this.program.instruction.updateDiscountMint(discountMint, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateSpotMarketMarginWeights(spotMarketIndex, initialAssetWeight, maintenanceAssetWeight, initialLiabilityWeight, maintenanceLiabilityWeight, imfFactor = 0) {
        const updateSpotMarketMarginWeightsIx = await this.getUpdateSpotMarketMarginWeightsIx(spotMarketIndex, initialAssetWeight, maintenanceAssetWeight, initialLiabilityWeight, maintenanceLiabilityWeight, imfFactor);
        const tx = await this.buildTransaction(updateSpotMarketMarginWeightsIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketMarginWeightsIx(spotMarketIndex, initialAssetWeight, maintenanceAssetWeight, initialLiabilityWeight, maintenanceLiabilityWeight, imfFactor = 0) {
        return await this.program.instruction.updateSpotMarketMarginWeights(initialAssetWeight, maintenanceAssetWeight, initialLiabilityWeight, maintenanceLiabilityWeight, imfFactor, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketBorrowRate(spotMarketIndex, optimalUtilization, optimalBorrowRate, optimalMaxRate, minBorrowRate) {
        const updateSpotMarketBorrowRateIx = await this.getUpdateSpotMarketBorrowRateIx(spotMarketIndex, optimalUtilization, optimalBorrowRate, optimalMaxRate, minBorrowRate);
        const tx = await this.buildTransaction(updateSpotMarketBorrowRateIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketBorrowRateIx(spotMarketIndex, optimalUtilization, optimalBorrowRate, optimalMaxRate, minBorrowRate) {
        return await this.program.instruction.updateSpotMarketBorrowRate(optimalUtilization, optimalBorrowRate, optimalMaxRate, minBorrowRate, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketAssetTier(spotMarketIndex, assetTier) {
        const updateSpotMarketAssetTierIx = await this.getUpdateSpotMarketAssetTierIx(spotMarketIndex, assetTier);
        const tx = await this.buildTransaction(updateSpotMarketAssetTierIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketAssetTierIx(spotMarketIndex, assetTier) {
        return await this.program.instruction.updateSpotMarketAssetTier(assetTier, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketStatus(spotMarketIndex, marketStatus) {
        const updateSpotMarketStatusIx = await this.getUpdateSpotMarketStatusIx(spotMarketIndex, marketStatus);
        const tx = await this.buildTransaction(updateSpotMarketStatusIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketStatusIx(spotMarketIndex, marketStatus) {
        return await this.program.instruction.updateSpotMarketStatus(marketStatus, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSpotMarketPausedOperations(spotMarketIndex, pausedOperations) {
        const updateSpotMarketPausedOperationsIx = await this.getUpdateSpotMarketPausedOperationsIx(spotMarketIndex, pausedOperations);
        const tx = await this.buildTransaction(updateSpotMarketPausedOperationsIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketPausedOperationsIx(spotMarketIndex, pausedOperations) {
        return await this.program.instruction.updateSpotMarketPausedOperations(pausedOperations, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updatePerpMarketStatus(perpMarketIndex, marketStatus) {
        const updatePerpMarketStatusIx = await this.getUpdatePerpMarketStatusIx(perpMarketIndex, marketStatus);
        const tx = await this.buildTransaction(updatePerpMarketStatusIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketStatusIx(perpMarketIndex, marketStatus) {
        return await this.program.instruction.updatePerpMarketStatus(marketStatus, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketPausedOperations(perpMarketIndex, pausedOperations) {
        const updatePerpMarketPausedOperationsIx = await this.getUpdatePerpMarketPausedOperationsIx(perpMarketIndex, pausedOperations);
        const tx = await this.buildTransaction(updatePerpMarketPausedOperationsIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketPausedOperationsIx(perpMarketIndex, pausedOperations) {
        return await this.program.instruction.updatePerpMarketPausedOperations(pausedOperations, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketContractTier(perpMarketIndex, contractTier) {
        const updatePerpMarketContractTierIx = await this.getUpdatePerpMarketContractTierIx(perpMarketIndex, contractTier);
        const tx = await this.buildTransaction(updatePerpMarketContractTierIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketContractTierIx(perpMarketIndex, contractTier) {
        return await this.program.instruction.updatePerpMarketContractTier(contractTier, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateExchangeStatus(exchangeStatus) {
        const updateExchangeStatusIx = await this.getUpdateExchangeStatusIx(exchangeStatus);
        const tx = await this.buildTransaction(updateExchangeStatusIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateExchangeStatusIx(exchangeStatus) {
        return await this.program.instruction.updateExchangeStatus(exchangeStatus, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updatePerpAuctionDuration(minDuration) {
        const updatePerpAuctionDurationIx = await this.getUpdatePerpAuctionDurationIx(minDuration);
        const tx = await this.buildTransaction(updatePerpAuctionDurationIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpAuctionDurationIx(minDuration) {
        return await this.program.instruction.updatePerpAuctionDuration(typeof minDuration === 'number' ? minDuration : minDuration.toNumber(), {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updateSpotAuctionDuration(defaultAuctionDuration) {
        const updateSpotAuctionDurationIx = await this.getUpdateSpotAuctionDurationIx(defaultAuctionDuration);
        const tx = await this.buildTransaction(updateSpotAuctionDurationIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotAuctionDurationIx(defaultAuctionDuration) {
        return await this.program.instruction.updateSpotAuctionDuration(defaultAuctionDuration, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
            },
        });
    }
    async updatePerpMarketMaxFillReserveFraction(perpMarketIndex, maxBaseAssetAmountRatio) {
        const updatePerpMarketMaxFillReserveFractionIx = await this.getUpdatePerpMarketMaxFillReserveFractionIx(perpMarketIndex, maxBaseAssetAmountRatio);
        const tx = await this.buildTransaction(updatePerpMarketMaxFillReserveFractionIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketMaxFillReserveFractionIx(perpMarketIndex, maxBaseAssetAmountRatio) {
        return await this.program.instruction.updatePerpMarketMaxFillReserveFraction(maxBaseAssetAmountRatio, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateMaxSlippageRatio(perpMarketIndex, maxSlippageRatio) {
        const updateMaxSlippageRatioIx = await this.getUpdateMaxSlippageRatioIx(perpMarketIndex, maxSlippageRatio);
        const tx = await this.buildTransaction(updateMaxSlippageRatioIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateMaxSlippageRatioIx(perpMarketIndex, maxSlippageRatio) {
        return await this.program.instruction.updateMaxSlippageRatio(maxSlippageRatio, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: this.getPerpMarketAccount(perpMarketIndex).pubkey,
            },
        });
    }
    async updatePerpMarketUnrealizedAssetWeight(perpMarketIndex, unrealizedInitialAssetWeight, unrealizedMaintenanceAssetWeight) {
        const updatePerpMarketUnrealizedAssetWeightIx = await this.getUpdatePerpMarketUnrealizedAssetWeightIx(perpMarketIndex, unrealizedInitialAssetWeight, unrealizedMaintenanceAssetWeight);
        const tx = await this.buildTransaction(updatePerpMarketUnrealizedAssetWeightIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketUnrealizedAssetWeightIx(perpMarketIndex, unrealizedInitialAssetWeight, unrealizedMaintenanceAssetWeight) {
        return await this.program.instruction.updatePerpMarketUnrealizedAssetWeight(unrealizedInitialAssetWeight, unrealizedMaintenanceAssetWeight, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketMaxImbalances(perpMarketIndex, unrealizedMaxImbalance, maxRevenueWithdrawPerPeriod, quoteMaxInsurance) {
        const updatePerpMarketMaxImabalancesIx = await this.getUpdatePerpMarketMaxImbalancesIx(perpMarketIndex, unrealizedMaxImbalance, maxRevenueWithdrawPerPeriod, quoteMaxInsurance);
        const tx = await this.buildTransaction(updatePerpMarketMaxImabalancesIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketMaxImbalancesIx(perpMarketIndex, unrealizedMaxImbalance, maxRevenueWithdrawPerPeriod, quoteMaxInsurance) {
        return await this.program.instruction.updatePerpMarketMaxImbalances(unrealizedMaxImbalance, maxRevenueWithdrawPerPeriod, quoteMaxInsurance, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketMaxOpenInterest(perpMarketIndex, maxOpenInterest) {
        const updatePerpMarketMaxOpenInterestIx = await this.getUpdatePerpMarketMaxOpenInterestIx(perpMarketIndex, maxOpenInterest);
        const tx = await this.buildTransaction(updatePerpMarketMaxOpenInterestIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketMaxOpenInterestIx(perpMarketIndex, maxOpenInterest) {
        return await this.program.instruction.updatePerpMarketMaxOpenInterest(maxOpenInterest, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketNumberOfUser(perpMarketIndex, numberOfUsers, numberOfUsersWithBase) {
        const updatepPerpMarketFeeAdjustmentIx = await this.getUpdatePerpMarketNumberOfUsersIx(perpMarketIndex, numberOfUsers, numberOfUsersWithBase);
        const tx = await this.buildTransaction(updatepPerpMarketFeeAdjustmentIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketNumberOfUsersIx(perpMarketIndex, numberOfUsers, numberOfUsersWithBase) {
        return await this.program.instruction.updatePerpMarketNumberOfUsers(numberOfUsers, numberOfUsersWithBase, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updatePerpMarketFeeAdjustment(perpMarketIndex, feeAdjustment) {
        const updatepPerpMarketFeeAdjustmentIx = await this.getUpdatePerpMarketFeeAdjustmentIx(perpMarketIndex, feeAdjustment);
        const tx = await this.buildTransaction(updatepPerpMarketFeeAdjustmentIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketFeeAdjustmentIx(perpMarketIndex, feeAdjustment) {
        return await this.program.instruction.updatePerpMarketFeeAdjustment(feeAdjustment, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateSpotMarketFeeAdjustment(perpMarketIndex, feeAdjustment) {
        const updateSpotMarketFeeAdjustmentIx = await this.getUpdateSpotMarketFeeAdjustmentIx(perpMarketIndex, feeAdjustment);
        const tx = await this.buildTransaction(updateSpotMarketFeeAdjustmentIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketFeeAdjustmentIx(spotMarketIndex, feeAdjustment) {
        return await this.program.instruction.updateSpotMarketFeeAdjustment(feeAdjustment, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async updateSerumVault(srmVault) {
        const updateSerumVaultIx = await this.getUpdateSerumVaultIx(srmVault);
        const tx = await this.buildTransaction(updateSerumVaultIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSerumVaultIx(srmVault) {
        return await this.program.instruction.updateSerumVault(srmVault, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                srmVault: srmVault,
            },
        });
    }
    async updatePerpMarketLiquidationFee(perpMarketIndex, liquidatorFee, ifLiquidationFee) {
        const updatePerpMarketLiquidationFeeIx = await this.getUpdatePerpMarketLiquidationFeeIx(perpMarketIndex, liquidatorFee, ifLiquidationFee);
        const tx = await this.buildTransaction(updatePerpMarketLiquidationFeeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketLiquidationFeeIx(perpMarketIndex, liquidatorFee, ifLiquidationFee) {
        return await this.program.instruction.updatePerpMarketLiquidationFee(liquidatorFee, ifLiquidationFee, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateSpotMarketLiquidationFee(spotMarketIndex, liquidatorFee, ifLiquidationFee) {
        const updateSpotMarketLiquidationFeeIx = await this.getUpdateSpotMarketLiquidationFeeIx(spotMarketIndex, liquidatorFee, ifLiquidationFee);
        const tx = await this.buildTransaction(updateSpotMarketLiquidationFeeIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketLiquidationFeeIx(spotMarketIndex, liquidatorFee, ifLiquidationFee) {
        return await this.program.instruction.updateSpotMarketLiquidationFee(liquidatorFee, ifLiquidationFee, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex),
            },
        });
    }
    async initializeProtocolIfSharesTransferConfig() {
        const initializeProtocolIfSharesTransferConfigIx = await this.getInitializeProtocolIfSharesTransferConfigIx();
        const tx = await this.buildTransaction(initializeProtocolIfSharesTransferConfigIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitializeProtocolIfSharesTransferConfigIx() {
        return await this.program.instruction.initializeProtocolIfSharesTransferConfig({
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: anchor.web3.SystemProgram.programId,
                protocolIfSharesTransferConfig: (0, pda_1.getProtocolIfSharesTransferConfigPublicKey)(this.program.programId),
            },
        });
    }
    async updateProtocolIfSharesTransferConfig(whitelistedSigners, maxTransferPerEpoch) {
        const updateProtocolIfSharesTransferConfigIx = await this.getUpdateProtocolIfSharesTransferConfigIx(whitelistedSigners, maxTransferPerEpoch);
        const tx = await this.buildTransaction(updateProtocolIfSharesTransferConfigIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateProtocolIfSharesTransferConfigIx(whitelistedSigners, maxTransferPerEpoch) {
        return await this.program.instruction.updateProtocolIfSharesTransferConfig(whitelistedSigners || null, maxTransferPerEpoch, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                protocolIfSharesTransferConfig: (0, pda_1.getProtocolIfSharesTransferConfigPublicKey)(this.program.programId),
            },
        });
    }
    async initializePrelaunchOracle(perpMarketIndex, price, maxPrice) {
        const initializePrelaunchOracleIx = await this.getInitializePrelaunchOracleIx(perpMarketIndex, price, maxPrice);
        const tx = await this.buildTransaction(initializePrelaunchOracleIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitializePrelaunchOracleIx(perpMarketIndex, price, maxPrice) {
        const params = {
            perpMarketIndex,
            price: price || null,
            maxPrice: maxPrice || null,
        };
        return await this.program.instruction.initializePrelaunchOracle(params, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                prelaunchOracle: await (0, pda_1.getPrelaunchOraclePublicKey)(this.program.programId, perpMarketIndex),
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: anchor.web3.SystemProgram.programId,
            },
        });
    }
    async updatePrelaunchOracleParams(perpMarketIndex, price, maxPrice) {
        const updatePrelaunchOracleParamsIx = await this.getUpdatePrelaunchOracleParamsIx(perpMarketIndex, price, maxPrice);
        const tx = await this.buildTransaction(updatePrelaunchOracleParamsIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePrelaunchOracleParamsIx(perpMarketIndex, price, maxPrice) {
        const params = {
            perpMarketIndex,
            price: price || null,
            maxPrice: maxPrice || null,
        };
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.updatePrelaunchOracleParams(params, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: perpMarketPublicKey,
                prelaunchOracle: await (0, pda_1.getPrelaunchOraclePublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async deletePrelaunchOracle(perpMarketIndex) {
        const deletePrelaunchOracleIx = await this.getDeletePrelaunchOracleIx(perpMarketIndex);
        const tx = await this.buildTransaction(deletePrelaunchOracleIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getDeletePrelaunchOracleIx(perpMarketIndex, price, maxPrice) {
        const params = {
            perpMarketIndex,
            price: price || null,
            maxPrice: maxPrice || null,
        };
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.deletePrelaunchOracle(params, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: perpMarketPublicKey,
                prelaunchOracle: await (0, pda_1.getPrelaunchOraclePublicKey)(this.program.programId, perpMarketIndex),
            },
        });
    }
    async updateSpotMarketFuel(spotMarketIndex, fuelBoostDeposits, fuelBoostBorrows, fuelBoostTaker, fuelBoostMaker, fuelBoostInsurance) {
        const updateSpotMarketFuelIx = await this.getUpdateSpotMarketFuelIx(spotMarketIndex, fuelBoostDeposits || null, fuelBoostBorrows || null, fuelBoostTaker || null, fuelBoostMaker || null, fuelBoostInsurance || null);
        const tx = await this.buildTransaction(updateSpotMarketFuelIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateSpotMarketFuelIx(spotMarketIndex, fuelBoostDeposits, fuelBoostBorrows, fuelBoostTaker, fuelBoostMaker, fuelBoostInsurance) {
        const spotMarketPublicKey = await (0, pda_1.getSpotMarketPublicKey)(this.program.programId, spotMarketIndex);
        return await this.program.instruction.updateSpotMarketFuel(fuelBoostDeposits || null, fuelBoostBorrows || null, fuelBoostTaker || null, fuelBoostMaker || null, fuelBoostInsurance || null, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                spotMarket: spotMarketPublicKey,
            },
        });
    }
    async updatePerpMarketFuel(perpMarketIndex, fuelBoostTaker, fuelBoostMaker, fuelBoostPosition) {
        const updatePerpMarketFuelIx = await this.getUpdatePerpMarketFuelIx(perpMarketIndex, fuelBoostTaker || null, fuelBoostMaker || null, fuelBoostPosition || null);
        const tx = await this.buildTransaction(updatePerpMarketFuelIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdatePerpMarketFuelIx(perpMarketIndex, fuelBoostTaker, fuelBoostMaker, fuelBoostPosition) {
        const perpMarketPublicKey = await (0, pda_1.getPerpMarketPublicKey)(this.program.programId, perpMarketIndex);
        return await this.program.instruction.updatePerpMarketFuel(fuelBoostTaker || null, fuelBoostMaker || null, fuelBoostPosition || null, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                perpMarket: perpMarketPublicKey,
            },
        });
    }
    async initUserFuel(user, authority, fuelBonusDeposits, fuelBonusBorrows, fuelBonusTaker, fuelBonusMaker, fuelBonusInsurance) {
        const updatePerpMarketFuelIx = await this.getInitUserFuelIx(user, authority, fuelBonusDeposits, fuelBonusBorrows, fuelBonusTaker, fuelBonusMaker, fuelBonusInsurance);
        const tx = await this.buildTransaction(updatePerpMarketFuelIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitUserFuelIx(user, authority, fuelBonusDeposits, fuelBonusBorrows, fuelBonusTaker, fuelBonusMaker, fuelBonusInsurance) {
        const userStats = await (0, pda_1.getUserStatsAccountPublicKey)(this.program.programId, authority);
        return await this.program.instruction.initUserFuel(fuelBonusDeposits || null, fuelBonusBorrows || null, fuelBonusTaker || null, fuelBonusMaker || null, fuelBonusInsurance || null, {
            accounts: {
                admin: this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                user,
                userStats,
            },
        });
    }
    async initializePythPullOracle(feedId) {
        const initializePythPullOracleIx = await this.getInitializePythPullOracleIx(feedId);
        const tx = await this.buildTransaction(initializePythPullOracleIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitializePythPullOracleIx(feedId) {
        const feedIdBuffer = (0, pythPullOracleUtils_1.getFeedIdUint8Array)(feedId);
        return await this.program.instruction.initializePythPullOracle(feedIdBuffer, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                systemProgram: web3_js_1.SystemProgram.programId,
                priceFeed: (0, pda_1.getPythPullOraclePublicKey)(this.program.programId, feedIdBuffer),
                pythSolanaReceiver: config_1.DRIFT_ORACLE_RECEIVER_ID,
            },
        });
    }
    async initializeHighLeverageModeConfig(maxUsers) {
        const initializeHighLeverageModeConfigIx = await this.getInitializeHighLeverageModeConfigIx(maxUsers);
        const tx = await this.buildTransaction(initializeHighLeverageModeConfigIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getInitializeHighLeverageModeConfigIx(maxUsers) {
        return await this.program.instruction.initializeHighLeverageModeConfig(maxUsers, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: anchor.web3.SystemProgram.programId,
                highLeverageModeConfig: (0, pda_1.getHighLeverageModeConfigPublicKey)(this.program.programId),
            },
        });
    }
    async updateUpdateHighLeverageModeConfig(maxUsers, reduceOnly) {
        const updateHighLeverageModeConfigIx = await this.getUpdateHighLeverageModeConfigIx(maxUsers, reduceOnly);
        const tx = await this.buildTransaction(updateHighLeverageModeConfigIx);
        const { txSig } = await this.sendTransaction(tx, [], this.opts);
        return txSig;
    }
    async getUpdateHighLeverageModeConfigIx(maxUsers, reduceOnly) {
        return await this.program.instruction.updateHighLeverageModeConfig(maxUsers, reduceOnly, {
            accounts: {
                admin: this.isSubscribed
                    ? this.getStateAccount().admin
                    : this.wallet.publicKey,
                state: await this.getStatePublicKey(),
                highLeverageModeConfig: (0, pda_1.getHighLeverageModeConfigPublicKey)(this.program.programId),
            },
        });
    }
}
exports.AdminClient = AdminClient;

'use client';

import { useWindowSizeStore } from 'src/stores/useWindowSizeStore';

/**
 * Returns a string representing the current window size. To use for conditional breakpoint rendering, do something like this:
 *
 * ['xs', 'sm'].includes(size)
 *
 * If you absolutely NEED to use the numerical values for width and height, you can get them from useWindowSizeStore directly.
 * However this will cause lots of rerenders when the window size changes.
 *
 * @returns {string} The current window size.
 */
const useWindowSizeString = () => {
	const size = useWindowSizeStore((s) => s.size);

	return size;
};

const sizeComparison = {
	xs: 0,
	sm: 1,
	md: 2,
	lg: 3,
	xl: 4,
};

/**
 * 'sm': <760px
 *
 * 'md': <1100px
 *
 * 'lg': <1250px
 *
 * 'xl': <1600px
 *
 * @param size
 * @returns
 */
export const useWindowSizeIsLessThan = (size: 'sm' | 'md' | 'lg' | 'xl') => {
	const currentSize = useWindowSizeString();

	return sizeComparison[currentSize] < sizeComparison[size];
};

/**
 * 'xs': >=760px
 *
 * 'sm': >=1100px
 *
 * 'md': >=1250px
 *
 * 'lg': >=1600px
 *
 * @param size
 * @returns
 */
export const useWindowSizeIsGreaterThan = (size: 'xs' | 'sm' | 'md' | 'lg') => {
	const currentSize = useWindowSizeString();

	return sizeComparison[currentSize] > sizeComparison[size];
};

const heightComparison = {
	short: 0,
	medium: 1,
	tall: 2,
};

/**
 * 'medium': <700px
 *
 * 'tall': <1000px
 *
 * @param height
 * @returns
 */
export const useWindowHeightIsLessThan = (height: 'medium' | 'tall') => {
	const currentHeight = useWindowSizeStore((s) => s.heightString);

	return heightComparison[currentHeight] < heightComparison[height];
};

/**
 * 'short': >=700px
 *
 * 'medium': >=1000px
 *
 * @param height
 * @returns
 */
export const useWindowHeightIsGreaterThan = (height: 'short' | 'medium') => {
	const currentHeight = useWindowSizeStore((s) => s.heightString);

	return heightComparison[currentHeight] > heightComparison[height];
};

export default useWindowSizeString;

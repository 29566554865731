"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.grpcSubscription = void 0;
const memcmp_1 = require("../memcmp");
const grpcProgramAccountSubscriber_1 = require("../accounts/grpcProgramAccountSubscriber");
class grpcSubscription {
    constructor({ grpcConfigs, userMap, skipInitialLoad = false, resubOpts, includeIdle = false, decodeFn, }) {
        this.userMap = userMap;
        this.skipInitialLoad = skipInitialLoad;
        this.resubOpts = resubOpts;
        this.includeIdle = includeIdle || false;
        this.decodeFn = decodeFn;
        this.grpcConfigs = grpcConfigs;
    }
    async subscribe() {
        if (!this.subscriber) {
            const filters = [(0, memcmp_1.getUserFilter)()];
            if (!this.includeIdle) {
                filters.push((0, memcmp_1.getNonIdleUserFilter)());
            }
            this.subscriber = new grpcProgramAccountSubscriber_1.grpcProgramAccountSubscriber(this.grpcConfigs, 'UserMap', 'User', this.userMap.driftClient.program, this.decodeFn, {
                filters,
            }, this.resubOpts);
        }
        await this.subscriber.subscribe((accountId, account, context) => {
            const userKey = accountId.toBase58();
            this.userMap.updateUserAccount(userKey, account, context.slot);
        });
        if (!this.skipInitialLoad) {
            await this.userMap.sync();
        }
    }
    async unsubscribe() {
        if (!this.subscriber)
            return;
        await this.subscriber.unsubscribe();
        this.subscriber = undefined;
    }
}
exports.grpcSubscription = grpcSubscription;

import {
	ConfirmationStrategy,
	DriftEnv,
	OracleInfo,
	PublicKey,
	Wallet,
	initialize,
} from '@drift-labs/sdk';
import { CandleType } from '@drift/common';
import { Adapter } from '@solana/wallet-adapter-base';
import { PriorityFee } from '../@types/types';
import { Version } from '@solana/web3.js';

export type LocalEnv = DriftEnv | 'master';

export enum DriftTheme {
	light = 'light-theme',
	dark = 'dark-theme',
}

export type ToastsToIgnoreSettings = {
	walletVersionedTxCompatibility: boolean;
};

export type SubscriberType = 'polling' | 'websocket';
export type EventSubscriberType = SubscriberType | 'events-server';

export type UserSettings = {
	rpcEndpoint: {
		label: string;
		value: string;
	};
	mainnetOverride: boolean;
	stagingHistoryServerOverride: boolean;
	enableVersionedTx: boolean;
	showPnlOnClose: boolean;
	includeLeverageInPnl: boolean;
	showTradeConfirmation: boolean;
	slippageTolerance: string;
	allowInfSlippage: boolean;
	showAccountValues: boolean;
	showFillNotifications: boolean;
	showZeroValues: boolean;
	blinkOnValueChange: boolean;
	showPositionsAndOrdersOnChart: boolean;
	showMarketBar: boolean;
	loadSavedChartColors: boolean;
	perpPageTableSizePreference: string;
	priorityFee: 'custom' | 'dynamic' | 'boosted' | 'turbo';
	customPriorityFee: number;
	maxPriorityFeeCap: number;
	preferredCandleType: CandleType;
	enableSwapLeverage: boolean;
	charts?: any[];
	explorer: 'solana' | 'solscan' | 'xray' | 'solanafm';
	auctionDuration: number;
	auctionStartPriceOffsetFrom: 'oracle' | 'bestOffer' | 'entry' | 'best';
	auctionStartPriceOffset: number;
	auctionEndPriceOffset: number;
	oracleOffsetOrdersEnabled: boolean;
	customMarginRatio: number;
	accountSubscriberType: SubscriberType;
	eventSubscriberType: EventSubscriberType;
	cancelExistingOrdersOnClosePosition: boolean;
	ignoreToasts: ToastsToIgnoreSettings;
	placeAndTakeEnabled: boolean;
	directRouteSwaps: boolean;
	version?: number;
	traderProfile?: 'default' | 'aggressive' | 'passive' | 'custom';
};

export type EnvironmentVariables = {
	sdkEnv: DriftEnv;
	sdkConfig: ReturnType<typeof initialize>;
	localEnv: LocalEnv;
	driftClientProgramId: string;
	usdcMint: PublicKey;
	defaultRpcAddress: string;
	defaultRpcLabel: string;
	historyServerUrl: string;
	dlobServerHttpUrl: string;
	dlobWsServerToUse: string;
	eventsServerToUse?: string;
	mainnetTermsUrl: string;
	mainnetDisclaimerUrl: string;
	pollingFrequencyMs: number;
	defaultMaxMarketLeverage: number;
	oracleInfos: OracleInfo[];
	spotMarketIndexes: number[];
	perpMarketIndexes: number[];
	serumAddress: string;
	phoenixAddress: string;
	maxAccountsPerWallet: number;
	locale: string;
	enableSocialLogin: boolean;
	magicAuthApiKey: string;
	oAuthRedirectURI?: string;
	defaultWallet: Wallet;
	E2E_WALLET: Adapter;
	topLedgerClient: string;
	topLedgerDashboardToken: string;
	priceDivergenceWarningThreshold: number;
	minInitialDepositValue: number;
	defaultMakerRebateBps: number;
	defaultTakerFeeBps: number;
	maxNumberOfAccountsPer24h: number;
	enableWormhole?: boolean;
	wormholePackageVersion: string;
	enableMmSnap?: boolean;
	mmSnapUrl?: string;
	mmSnapId?: string;
	mmSnapVersion?: string;
	showErrorLogs?: boolean;
	feedbackWidgetId?: number;
	enableSupportWidget?: boolean;
	enableDlobWebsocket?: boolean;
	metricsLoggingServerUrl?: string;
	metricsLoggingEnabled: boolean;
	metricsLoggingSampleRatio: number;
	mainnetSpeedInsightsEnabled?: boolean;
	dynamicPriorityFeeMultiplier?: number;
	uiEssentialUpdateIntervalMs?: number;
	uiNonEssentialUpdateIntervalMs?: number;
	dynamicFeeTargetPercentile?: number;
	confirmationStrategy: ConfirmationStrategy;
	driftPointsTestTimeMs?: number;
	feeSubscriptionPollingMultiplier?: number;
	balEntryPriceCutoffTs?: number;
	enableLiteMode?: boolean;
	maxAccountsSwapBuffer?: number;
	enableDlobWebsocketTradesChannel?: boolean;
	driftDialectDappAddress: string;
	dialectNotificationsEnabled: boolean;
	useSimulatedComputeUnitBudget: boolean;
	useSimulatedComputeUnitBudgetForFees: boolean;
	computeUnitBufferMultiplier: number;
	minimumPriorityFee: PriorityFee;
	txSenderRetryInterval?: number;
	driftTraderRewardsBannerEnabled: boolean;
	initialDataSourceMaxDelay?: number;
	newDataSourceSubMaxDelay?: number;
	upgradeDataSourceDelay?: number;
	placeAndTakeLowerBoundCu?: number;
	useCachedBlockhashFetcherInDriftClient: boolean;
	showPredictionMarketsInPerpTradePage: boolean;
	pyusdBonusPerWeek: number;
	enableFuelEarnings?: boolean;
	fuelBaseEarnRates: {
		taker: number;
		maker: number;
		deposit: number;
		borrow: number;
		oi: number;
		staking: number;
	};
	showAppKitWallet: boolean;
	rpcVersion?: Version;
	depositAndTradeEnabled: boolean;
};

'use client';

import Text from 'src/components/Text/Text';
import React, { memo, ReactNode, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

const FORMLABEL01 = (props: {
	label: string | ReactNode;
	className?: string;
}) => {
	const memoedClassName = useMemo(
		() => twMerge(`text-text-label`, props.className),
		[props.className]
	);

	return (
		<Text.INPUTLABEL01 className={memoedClassName}>
			{props.label}
		</Text.INPUTLABEL01>
	);
};

const FORMLABEL02 = (props: { label: string }) => {
	return <Text.MICRO3 className="text-neutrals-40">{props.label}</Text.MICRO3>;
};

const VERTSPACERXXS = () => <span className="flex-shrink-0 w-full pb-xxs" />;
const VERTSPACERXS = () => <span className="flex-shrink-0 w-full pb-xs" />;
const VERTSPACERS = () => <span className="flex-shrink-0 w-full pb-s" />;
const VERTSPACERM = () => <span className="flex-shrink-0 w-full pb-m" />;
const VERTSPACERL = () => <span className="flex-shrink-0 w-full pb-l" />;
const VERTSPACERXL = () => <span className="flex-shrink-0 w-full pb-xl" />;

const VERTDIVIDER = () => (
	<span className="w-full border-b border-container-border" />
);

const Utility = {
	FORMLABEL01: memo(FORMLABEL01),
	FORMLABEL02: memo(FORMLABEL02),
	VERTSPACERXXS: memo(VERTSPACERXXS),
	VERTSPACERXS: memo(VERTSPACERXS),
	VERTSPACERS: memo(VERTSPACERS),
	VERTSPACERM: memo(VERTSPACERM),
	VERTSPACERL: memo(VERTSPACERL),
	VERTSPACERXL: memo(VERTSPACERXL),
	VERTDIVIDER: memo(VERTDIVIDER),
};

export default Utility;
